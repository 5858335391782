/**
 * A custom element that wraps another element and sets its `aria-busy` attribute to `true` when the wrapped element is busy.
 * It assumes the first child of the element is the element to be wrapped, either a form, button, or link.
 *
 * @example
 * <busy-wrapper>
 *   <button>Submit</button>
 * </busy-wrapper>
 */
export class BusyWrapper extends HTMLElement {
  constructor() {
    super();
    this._wrapped = null;
    this._busyTarget = null;
    this._observer = null;
  }

  connectedCallback() {
    this._wrapped = this.children[0];
    if (!this._wrapped) return;

    // Set the busy target
    const targetSelector = this.getAttribute('busy-target');
    this._busyTarget = targetSelector ? this._wrapped.querySelector(targetSelector) : this._wrapped;

    if (this._wrapped.tagName === 'FORM') {
      this._wrapped.addEventListener('submit', this._handleSubmit.bind(this));
      // For forms, default to setting aria-busy on the submit button if no target is specified
      if (!targetSelector) {
        this._busyTarget = this._wrapped.querySelector('button[type="submit"]') || this._wrapped;
      }
    } else if (this._wrapped.tagName === 'BUTTON' || this._wrapped.tagName === 'A') {
      this._wrapped.addEventListener('click', this._handleClick.bind(this));
    }

    this._setupMutationObserver();
  }

  disconnectedCallback() {
    if (this._wrapped) {
      if (this._wrapped.tagName === 'FORM') {
        this._wrapped.removeEventListener('submit', this._handleSubmit);
      } else if (this._wrapped.tagName === 'BUTTON' || this._wrapped.tagName === 'A') {
        this._wrapped.removeEventListener('click', this._handleClick);
      }
    }
    if (this._observer) {
      this._observer.disconnect();
    }
  }

  _setupMutationObserver() {
    this._observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          this._updateAriaBusy();
        }
      }
    });

    this._observer.observe(this._wrapped, { attributes: true });
  }

  _handleSubmit(event) {
    if (!event.defaultPrevented) {
      this._setBusy(true);
    }
  }

  _handleClick(event) {
    if (!event.defaultPrevented && !this._wrapped.hasAttribute('href')) {
      this._setBusy(true);
    }
  }

  _setBusy(isBusy) {
    if (this._busyTarget) {
      this._busyTarget.setAttribute('aria-busy', isBusy);
    }
  }

  _updateAriaBusy() {
    const isHtmxRequest = this._wrapped.classList.contains('htmx-request');
    this._setBusy(isHtmxRequest);
  }
}
