export class SelectorBox extends HTMLElement {
  constructor () {
    super()
    this.originalSelect = null
    this.visibleSelect = null
    this.selectedOptionsContainer = null
  }

  connectedCallback () {
    this.initializeComponent()
  }

  initializeComponent () {
    this.originalSelect = this.querySelector('select')
    if (!this.originalSelect) {
      console.error('SelectorComponent: No select element found')
      return
    }

    this.render()
    this.addEventListeners()
    this.updateVisibleSelect()
    this.updateSelectedOptions()
  }

  render () {
    this.innerHTML = ''

    this.visibleSelect = document.createElement('select')
    this.visibleSelect.name = 'Visible' + this.originalSelect.name
    this.visibleSelect.innerHTML = '<option value="">Select an option</option>'

    this.selectedOptionsContainer = document.createElement('div')
    this.selectedOptionsContainer.className = 'selected-options'

    this.appendChild(this.originalSelect)
    this.appendChild(this.visibleSelect)
    this.appendChild(this.selectedOptionsContainer)

    this.originalSelect.style.display = 'none'
  }

  addEventListeners () {
    this.visibleSelect.addEventListener('change', () => this.handleSelection())
    this.selectedOptionsContainer.addEventListener('click', (e) => {
      if (e.target.tagName === 'A') {
        e.preventDefault()
        this.handleRemoval(e.target.closest('.selected-option').dataset.value)
      }
    })

    // Add htmx event listeners
    document.body.addEventListener('htmx:afterSwap', (event) => {
      if (this.contains(event.detail.evt) || event.detail.elt.contains(this)) {
        this.initializeComponent()
      }
    })
  }

  updateVisibleSelect () {
    let selectableLabel = 'Select an option'
    const selectableOptions = Array.from(this.originalSelect.options)
      .filter(option => !option.selected)

    if (selectableOptions.length === 0) {
      selectableLabel = 'No more options available'
    }

    this.visibleSelect.innerHTML = `<option value="">${selectableLabel}</option>` +
      selectableOptions.map(option => `<option value="${option.value}">${option.text}</option>`).join('')
  }

  updateSelectedOptions () {
    const selectedOptions = Array.from(this.originalSelect.selectedOptions)

    this.selectedOptionsContainer.innerHTML = selectedOptions.map(option => `
      <span class="selected-option" data-value="${option.value}">
        ${option.text}
        <a href="#" class="remove-option">×</a>
      </span>
    `).join('')
  }

  handleSelection () {
    const selectedValue = this.visibleSelect.value
    if (!selectedValue) return

    const option = this.originalSelect.querySelector(`option[value="${selectedValue}"]`)
    option.selected = true

    this.updateVisibleSelect()
    this.updateSelectedOptions()
    this.visibleSelect.value = ''
  }

  handleRemoval (value) {
    const option = this.originalSelect.querySelector(`option[value="${value}"]`)
    option.selected = false

    this.updateVisibleSelect()
    this.updateSelectedOptions()
  }
}
