// JS
import htmx from 'htmx.org/dist/htmx.esm.js'
import Sortable  from 'sortablejs'
import {
  BusyWrapper,
  CountSelector,
  CopyToClipboard,
  DropdownMenu,
  FileUpload,
  MobileMenu,
  PasswordToggle,
  PhoneNumberInput,
  SelectorBox,
  ToastNotification,
  TopicCard,
  TopicSelector,
  UsernameInput
} from './components/index.js'

window.htmx = htmx

// Config object
const config = {
  debounceDelay: 350,
  waitForComponents: [],
}

const componentsMap = {
  'busy-wrapper': BusyWrapper,
  'count-selector': CountSelector,
  'copy-to-clipboard': CopyToClipboard,
  'dropdown-menu': DropdownMenu,
  'file-upload': FileUpload,
  'mobile-menu': MobileMenu,
  'password-toggle': PasswordToggle,
  'phone-number-input': PhoneNumberInput,
  'selector-box': SelectorBox,
  'toast-notification': ToastNotification,
  'topic-card': TopicCard,
  'topic-selector': TopicSelector,
  'username-input': UsernameInput,
}

document.body.addEventListener('htmx:beforeRequest', function (evt) {
  // Hide any existing toast messages
  const toast = document.getElementById('ToastNotification')
  if (toast) {
    toast.hide()
  }
})

document.body.addEventListener('htmx:beforeSwap', function (evt) {
  if (evt.detail.xhr.status === 400 || evt.detail.xhr.status === 404 || evt.detail.xhr.status === 422 || evt.detail.xhr.status === 500) {
    // if the response code is 404, 422, or 500, we want to swap the content
    evt.detail.shouldSwap = true
    // set isError to 'false' to avoid error logging in console
    evt.detail.isError = false
  }
})

// Handle flash messages and scroll to top
document.body.addEventListener('htmx:afterSwap', function (evt) {
  // Work around for scroll:top or scroll:window:top. For some reason,
  // these aren't consistent, so we just force it on our own.
  // Should be used with the usual `hx-swap` attribute (e.g. hx-swap="outerHTML scroll:top")
  const target = evt.detail.target
  if (target.hasAttribute('hx-swap')) {
    // If the requestConfig.elt has a 'hx-disinherit' attribute, with 'hx-swap', ignore the scroll:top
    if (evt.detail.requestConfig?.elt.hasAttribute('hx-disinherit')) {
      const disinherit = evt.detail.requestConfig.elt.getAttribute('hx-disinherit')
      if (disinherit.includes('hx-swap') || disinherit === '*') {
        return
      }
    }

    const swap = target.getAttribute('hx-swap')
    const vals = ['scroll:top', 'scroll:window:top']
    if (vals.some(val => swap.includes(val))) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  // Handle toast messages
  if (evt.detail.target && evt.detail.target.id === 'ToastNotificationMessage') {
    const toast = document.getElementById('ToastNotification')
    if (toast) {
      toast.show()
    }
  }
})

document.body.addEventListener('showNotification', function (evt) {
  const toast = document.getElementById('ToastNotification')
  if (toast) {
    toast.showWithLevel(evt.detail.level, evt.detail.message)
  }
})

const defineComponents = async (componentsMap = {}) => {
  const definePromises = []

  // Define and collect all custom elements
  for (const [name, elementClass] of Object.entries(componentsMap)) {
    if (!customElements.get(name)) {
      customElements.define(name, elementClass)
      const definePromise = customElements.whenDefined(name)
      definePromises.push(definePromise)
    }
  }

  // Wait for all custom elements to be defined
  await Promise.allSettled(definePromises)

  // Wait for any additional components to be defined
  if (config.waitForComponents.length > 0) {
    const waitForComponents = config.waitForComponents.map((componentName) => {
      return customElements.whenDefined(componentName)
    })

    await Promise.allSettled(waitForComponents)
  }
}

const applySortable = async (element) => {
  const sortables = element.querySelectorAll('.sortable')
  for (let i = 0; i < sortables.length; i++) {
    const sortable = sortables[i]
    new Sortable(sortable, {
      animation: 150,
      ghostClass: 'sortable-ghost',

      // Make the htmx-indicator unsortable
      filter: '.htmx-indicator',

      onMove: function (evt) {
        return evt.related.className.indexOf('htmx-indicator') === -1
      },

      // Make sure sorting is re-enabled after the drag ends
      onEnd: function (evt) {
        this.option('disabled', false)
      }
    })

    // Re-enable sorting on the `htmx:afterSwap` event
    sortable.addEventListener('htmx:afterSwap', function () {
      this.sortable.option('disabled', false)
    })
  }
}

// On page load, define all components
document.addEventListener('DOMContentLoaded', async () => {
  await defineComponents(componentsMap)

  // Remove the preload class from the body to show the page
  document.body.classList.remove('preload')

  // Redefine all components on htmx load
  window.htmx.onLoad(async function (element) {
    await defineComponents(componentsMap)
    await applySortable(element)
  })

  // Look for any meta values with x-replace-url and replace the current URL with the new URL in the browser history
  const replaceUrlMeta = document.querySelector('meta[name="x-replace-url"]')
  if (replaceUrlMeta) {
    const newUrl = replaceUrlMeta.getAttribute('content')
    window.history.replaceState({}, '', newUrl)
  }
})
