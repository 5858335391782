/**
 * Custom element for formatting and validating phone numbers
 * @element phone-number-input
 * @attr {string} format - The format to display the phone number in (default: '(xxx) xxx-xxxx')
 * @attr {string} pattern - The regex pattern to validate the phone number against (default: '^\(\d{3}\)\s?\d{3}-\d{4}$')
 * @attr {string} placeholder - The placeholder text to display in the input (default: '(xxx) xxx-xxxx')
 */
export class PhoneNumberInput extends HTMLElement {
  constructor() {
    super();
    this.input = null;
    this.format = this.getAttribute('format') || '(xxx) xxx-xxxx';
    this.pattern = this.getAttribute('pattern') || '^\\(\\d{3}\\)\\s?\\d{3}-\\d{4}$';
    this.placeholder = this.getAttribute('placeholder') || this.format;
  }

  connectedCallback() {
    this.input = this.querySelector('input[type="tel"]');

    if (!this.input) {
      console.error('PhoneNumberInput: No input element found. Please include an input element of type "tel".');
      return;
    }

    // Set attributes for accessibility and usability
    this.input.setAttribute('aria-label', this.input.getAttribute('aria-label') || 'Phone number');
    this.input.setAttribute('autocomplete', 'tel');
    this.input.setAttribute('placeholder', this.placeholder);
    this.input.setAttribute('pattern', this.pattern);

    this.input.addEventListener('input', this.formatPhoneNumber.bind(this));
    this.input.addEventListener('keydown', this.handleKeyDown.bind(this));
    this.input.addEventListener('blur', this.validatePhoneNumber.bind(this));
  }

  formatPhoneNumber() {
    let value = this.input.value.replace(/\D/g, '');
    let formattedValue = this.format;

    for (let i = 0; i < value.length; i++) {
      formattedValue = formattedValue.replace('x', value[i]);
    }

    // Remove any remaining 'x' placeholders
    formattedValue = formattedValue.replace(/x/g, '');

    this.input.value = formattedValue;
  }

  handleKeyDown(event) {
    const allowedChars = /[0-9\s+\-()]/;
    const key = event.key;

    if (key === 'Tab') {
      return;
    }

    if (!allowedChars.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault();
    }
  }

  validatePhoneNumber() {
    const phoneNumber = this.input.value;
    const regex = new RegExp(this.pattern);

    if (!regex.test(phoneNumber)) {
      this.input.setCustomValidity('Please enter a valid phone number');
      this.input.setAttribute('aria-invalid', 'true');
    } else {
      this.input.setCustomValidity('');
      this.input.removeAttribute('aria-invalid');
    }
    // this.input.reportValidity();
  }

  // Getter for the formatted phone number
  get value() {
    return this.input ? this.input.value : '';
  }

  // Setter for the phone number
  set value(newValue) {
    if (this.input) {
      this.input.value = newValue;
      this.formatPhoneNumber();
    }
  }

  // Observe attributes for changes
  static get observedAttributes() {
    return ['format', 'pattern', 'placeholder'];
  }

  // Handle attribute changes
  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this[name] = newValue;
      if (this.input) {
        if (name === 'pattern') {
          this.input.setAttribute('pattern', newValue);
        } else if (name === 'placeholder') {
          this.input.setAttribute('placeholder', newValue);
        }
        this.formatPhoneNumber();
      }
    }
  }
}
