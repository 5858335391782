export class CountSelector extends HTMLElement {
  constructor() {
    super();
    this.render();
  }

  render() {
    const id = this.getAttribute('id') || 'count-selector';
    const name = this.getAttribute('name') || 'count';
    const label = this.getAttribute('label') || 'Count';
    const min = this.getAttribute('min') || '0';
    const max = this.getAttribute('max') || '100';
    const value = this.getAttribute('value') || min;

    this.innerHTML = `
            <div class="count-selector">
                <input type="number" id="${id}" name="${name}" min="${min}" max="${max}" value="${value}">
                <input type="range" id="${id}-slider" min="${min}" max="${max}" value="${value}">
                <div class="slider-labels">
                    <span class="min-label">${min}</span>
                    <span class="max-label">${max}</span>
                </div>
                <div id="${id}-error" role="alert" aria-live="assertive"></div>
            </div>
        `;
  }

  connectedCallback() {
    this.input = this.querySelector(`#${this.getAttribute('id') || 'count-selector'}`);
    this.slider = this.querySelector(`#${this.getAttribute('id') || 'count-selector'}-slider`);
    this.errorMessage = this.querySelector(`#${this.getAttribute('id') || 'count-selector'}-error`);
    this.minLabel = this.querySelector('.min-label');
    this.maxLabel = this.querySelector('.max-label');

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.input.addEventListener('input', () => this.handleInputChange());
    this.slider.addEventListener('input', () => this.handleSliderChange());
  }

  handleInputChange() {
    const value = parseInt(this.input.value);
    if (this.isValidValue(value)) {
      this.slider.value = value;
      this.updateValue(value);
    }
  }

  handleSliderChange() {
    const value = parseInt(this.slider.value);
    this.input.value = value;
    this.updateValue(value);
  }

  isValidValue(value) {
    const min = parseInt(this.getAttribute('min') || '0');
    const max = parseInt(this.getAttribute('max') || '100');
    return !isNaN(value) && value >= min && value <= max;
  }

  updateValue(value) {
    if (this.isValidValue(value)) {
      this.errorMessage.textContent = '';
      this.dispatchEvent(new CustomEvent('change', { detail: { value } }));
    } else {
      const min = this.getAttribute('min') || '0';
      const max = this.getAttribute('max') || '100';
      this.errorMessage.textContent = `Please enter a number between ${min} and ${max}.`;
    }
  }

  static get observedAttributes() {
    return ['min', 'max', 'id', 'name', 'label', 'value'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.render();
      this.connectedCallback();
    }
  }
}
