export class ToastNotification extends HTMLElement {
  constructor () {
    super()
    this._level = 'info'
    this._message = ''
    this._visible = false
  }

  connectedCallback () {
    this.innerHTML = `
            <div class="toast">
                <span id="ToastNotificationMessage" class="toast-message"></span>
                <button class="toast-close transparent">
                  <svg class="icon icon-sm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </button>
            </div>
        `

    this.render()
  }

  static get observedAttributes () {
    return ['visible', 'message', 'level']
  }

  attributeChangedCallback (name, oldValue, newValue) {
    if (oldValue !== newValue) {
      if (name === 'visible') {
        this._visible = newValue !== null
      } else if (name === 'message') {
        this._message = newValue
      } else if (name === 'level') {
        this._level = newValue || 'info'
      }
      this.render()
    }
  }

  get visible () {
    return this._visible
  }

  set visible (value) {
    if (value) {
      this.setAttribute('visible', '')
    } else {
      this.removeAttribute('visible')
    }
  }

  get message () {
    return this._message
  }

  set message (value) {
    this.setAttribute('message', value)
  }

  get level () {
    return this._level
  }

  set level (value) {
    if (value) {
      this.setAttribute('level', value)
    }
  }

  render () {
    const toast = this.querySelector('.toast')
    if (toast) {
      toast.className = `toast ${this._visible ? 'show' : 'hide'} ${this.getAttribute('level') || 'info'}`
      const message = this.querySelector('#ToastNotificationMessage')
      if (message) {
        message.textContent = this._message
      }
    }
    this.setupEventListeners()
  }

  setupEventListeners () {
    const closeButton = this.querySelector('.toast-close')
    if (closeButton) {
      closeButton.removeEventListener('click', () => this.hide())
      closeButton.addEventListener('click', () => this.hide())
    }
  }

  show () {
    // Make sure we retrieve any new message before showing the toast
    this._message = this.querySelector('#ToastNotificationMessage').textContent
    this.visible = true
  }

  showWithLevel (level, message) {
    this.level = level
    this.message = message
    this.querySelector('#ToastNotificationMessage').textContent = message
    this.visible = true
  }

  hide () {
    this.visible = false
  }
}

