// Description: A custom element to toggle the visibility of password fields
// Dependencies: none
//
// Example:
// <password-toggle visible>
//   <button slot="toggle">
//    <span slot="show"><icon "eye-line"/></span>
//    <span slot="hide" class="hidden"><icon "eye-off-line"/></span>
//   </button>
//   <input type="password" />
//   <input type="password" />
// </password-toggle>
//
// Returns: Custom Element PasswordToggle
export class PasswordToggle extends HTMLElement {
  static properties = {
    visible: { type: Boolean, reflect: true }
  }

  constructor (config) {
    super()
    this.visible = false

    // Properties
    this.passwords = this.querySelectorAll('input[type="password"]')
    this.trigger = this.querySelector('[slot="toggle"]')
    if (!this.trigger) {
      return
    }

    // this.visible = this.hasAttribute('visible')
    this.eventHandler = this.handleToggle()

    // Add the aria attributes
    this.trigger.setAttribute('aria-pressed', this.visible)
    this.trigger.setAttribute('role', 'button')
  }

  handleToggle () {
    return (e) => {
      e.preventDefault()
      this.toggle()
    }
  }

  show () {
    for (let password of this.passwords) {
      password.setAttribute('type', 'text')
    }
    this.trigger.setAttribute('aria-pressed', true)
    // Toggle the hidden class on the trigger's inner children
    this.trigger.querySelector('[slot="show"]')?.classList.add('hidden')
    this.trigger.querySelector('[slot="hide"]')?.classList.remove('hidden')
  }

  hide () {
    for (let password of this.passwords) {
      password.setAttribute('type', 'password')
    }
    this.trigger.setAttribute('aria-pressed', false)
    this.trigger.querySelector('[slot="show"]')?.classList.remove('hidden')
    this.trigger.querySelector('[slot="hide"]')?.classList.add('hidden')
  }

  toggle () {
    let visible = this.trigger.getAttribute('aria-pressed') === 'true'
    if (visible) {
      this.hide()
    } else {
      this.show()
    }
  }

  // Lifecycle methods

  // Called when the element is inserted into a document, including into a shadow tree
  connectedCallback () {
    this.trigger.addEventListener('click', this.eventHandler)
    this.trigger.classList.remove('hidden')

    if (this.visible) {
      this.show()
    } else {
      this.hide()
    }
  }

  // Called when the element is removed from a document
  disconnectedCallback () {
    this.trigger.removeEventListener('click', this.eventHandler)
  }
}

