// export class CopyToClipboard extends HTMLElement {
//   connectedCallback() {
//     this.addEventListener('click', this.copyToClipboard.bind(this));
//   }
//
//   async copyToClipboard() {
//     const text = this.getAttribute('text') || '';
//     try {
//       await navigator.clipboard.writeText(text);
//       console.log('Text copied to clipboard');
//       this.dispatchEvent(new CustomEvent('copy-success', { bubbles: true }));
//     } catch (err) {
//       console.error('Failed to copy text: ', err);
//       this.dispatchEvent(new CustomEvent('copy-error', { bubbles: true, detail: err }));
//     }
//   }
// }

// export class CopyToClipboard extends HTMLElement {
//   constructor() {
//     super();
//     this.originalContent = '';
//     this.timeoutId = null;
//   }
//
//   connectedCallback() {
//     this.addEventListener('click', this.copyToClipboard.bind(this));
//   }
//
//   async copyToClipboard() {
//     const text = this.getAttribute('text') || '';
//     const successText = this.getAttribute('success-text') || 'Copied!';
//     const duration = parseInt(this.getAttribute('feedback-duration') || '2000', 10);
//
//     try {
//       await navigator.clipboard.writeText(text);
//       console.log('Text copied to clipboard');
//       this.showFeedback(successText, duration);
//       this.dispatchEvent(new CustomEvent('copy-success', { bubbles: true }));
//     } catch (err) {
//       console.error('Failed to copy text: ', err);
//       this.dispatchEvent(new CustomEvent('copy-error', { bubbles: true, detail: err }));
//     }
//   }
//
//   showFeedback(feedbackText, duration) {
//     // Store original content if not already stored
//     if (!this.originalContent) {
//       this.originalContent = this.innerHTML;
//     }
//
//     // Clear any existing timeout
//     if (this.timeoutId) {
//       clearTimeout(this.timeoutId);
//     }
//
//     // Show feedback
//     this.innerHTML = feedbackText;
//     this.classList.add('copy-feedback');
//
//     // Set timeout to revert changes
//     this.timeoutId = setTimeout(() => {
//       this.innerHTML = this.originalContent;
//       this.classList.remove('copy-feedback');
//       this.timeoutId = null;
//     }, duration);
//   }
// }

export class CopyToClipboard extends HTMLElement {
  constructor() {
    super();
    this.originalText = '';
    this.timeoutId = null;
  }

  connectedCallback() {
    this.addEventListener('click', this.copyToClipboard.bind(this));
  }

  async copyToClipboard() {
    const text = this.getAttribute('text') || '';
    const successText = this.getAttribute('success-text') || 'Copied!';
    const duration = parseInt(this.getAttribute('feedback-duration') || '2000', 10);

    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
      this.showFeedback(successText, duration);
      this.dispatchEvent(new CustomEvent('copy-success', { bubbles: true }));
    } catch (err) {
      console.error('Failed to copy text: ', err);
      this.dispatchEvent(new CustomEvent('copy-error', { bubbles: true, detail: err }));
    }
  }

  showFeedback(feedbackText, duration) {
    const containedElement = this.querySelector('button') || this.querySelector('*') || this;

    // Store original text if not already stored
    if (!this.originalText) {
      this.originalText = containedElement.textContent;
    }

    // Clear any existing timeout
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Show feedback
    containedElement.textContent = feedbackText;
    this.classList.add('copy-feedback');

    // Set timeout to revert changes
    this.timeoutId = setTimeout(() => {
      containedElement.textContent = this.originalText;
      this.classList.remove('copy-feedback');
      this.timeoutId = null;
    }, duration);
  }
}
