export class TopicCard extends HTMLElement {
  connectedCallback() {
    this.classList.add('topic-card');
    this.setAttribute('role', 'button');
    this.setAttribute('tabindex', '0');
    this.setupEventListeners();
  }

  setupEventListeners() {
    this.addEventListener('click', () => this.select());
    this.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        this.select();
      }
    });
  }

  select() {
    this.dispatchEvent(new CustomEvent('topic-selected', {
      bubbles: true,
      detail: {
        id: this.getAttribute('topic-id'),
        title: this.querySelector('.topic-title').textContent,
        duration: this.querySelector('.topic-duration').textContent,
        description: this.querySelector('.topic-description').textContent,
        thumbnail: this.querySelector('.topic-thumbnail').src
      }
    }));
  }
}
