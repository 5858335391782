export class TopicSelector extends HTMLElement {
  static InputName = 'SelectedTopicIDs';

  constructor() {
    super();
    this.classList.add('topic-selector');
    this._selectedTopics = new Set();
    this._requiredTopics = 1;
    this._isInitialized = false;
    this._isUpdating = false;
    this._topicsLabel = 'Topics';
  }

  static get observedAttributes() {
    return ['required-topics', 'selected-topic-ids', 'topics-label'];
  }

  connectedCallback() {
    if (!this._isInitialized) {
      this.setupInitialStructure();
      this.setupEventListeners();
      this._isInitialized = true;
    }
    this.initializeFromAttributes();
    this.render();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._isInitialized || this._isUpdating) return;

    if (name === 'required-topics') {
      this._requiredTopics = parseInt(newValue || '1', 10);
      this.render();
    } else if (name === 'selected-topic-ids' && oldValue !== newValue) {
      this.initializeFromAttributes();
      this.render();
    } else if (name === 'topics-label') {
      this._topicsLabel = newValue;
      this.render();
    }
  }

  setupInitialStructure() {
    if (!this.querySelector('.available-topics')) {
      const availableTopics = document.createElement('div');
      availableTopics.className = 'available-topics';
      this.prepend(availableTopics);
    }

    if (!this.querySelector('.selected-topics')) {
      const selectedTopics = document.createElement('div');
      selectedTopics.className = 'selected-topics';
      this.appendChild(selectedTopics);
    }

    if (!this.querySelector('input[name="' + TopicSelector.InputName + '"]')) {
      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = TopicSelector.InputName;
      this.appendChild(hiddenInput);
    }

    if (!this.querySelector('.selected-topics-summary')) {
      const selectedTopicsSummary = document.createElement('div');
      selectedTopicsSummary.className = 'selected-topics-summary';
      this.prepend(selectedTopicsSummary);
    }
  }

  setupEventListeners() {
    this.addEventListener('topic-selected', this.handleTopicSelection.bind(this));
    this.addEventListener('click', (e) => {
      if (e.target.closest('.remove-topic')) {
        e.preventDefault();
        this.removeTopic(e.target.closest('.remove-topic').getAttribute('data-id'));
      }
    });
  }

  initializeFromAttributes() {
    const requiredTopics = this.getAttribute('required-topics');
    if (requiredTopics) {
      this._requiredTopics = parseInt(requiredTopics, 10);
    }

    const selectedIds = this.getAttribute('selected-topic-ids') || '';
    const idArray = selectedIds.split(',').filter(Boolean);

    const topicsLabel = this.getAttribute('topics-label');
    if (topicsLabel) {
      this._topicsLabel = topicsLabel
    }

    this._selectedTopics.clear();
    idArray.forEach(id => {
      const topicCard = this.querySelector(`topic-card[topic-id="${id}"]`);
      if (topicCard) {
        this._selectedTopics.add(this.getTopicDataFromCard(topicCard));
      }
    });
  }

  getTopicDataFromCard(card) {
    return {
      id: card.getAttribute('topic-id'),
      slotCount: card.getAttribute('slot-count'),
      title: card.querySelector('.topic-title').textContent,
      duration: card.querySelector('.topic-duration').textContent,
      description: card.querySelector('.topic-description').textContent,
      thumbnail: card.querySelector('.topic-thumbnail').src
    };
  }

  selectedTopicsSlotCount() {
    // Get the total slot count of all selected topics
    return [...this._selectedTopics].reduce((acc, topic) => {
      const slotCount = parseInt(topic.slotCount, 10);
      if (isNaN(slotCount)) {
        return acc;
      }
      return acc + slotCount;
    }, 0);
  }

  handleTopicSelection(e) {
    e.preventDefault();
    const topicCard = e.target.closest('topic-card');
    const topic = this.getTopicDataFromCard(topicCard);
    let slotCount = parseInt(topic.slotCount, 10);
    if (isNaN(slotCount)) {
      slotCount = 1;
    }
    const selectedCount = this.selectedTopicsSlotCount();
    console.log('selectedCount + slotCount', selectedCount + slotCount);
    if (topicCard && (selectedCount + slotCount) <= this._requiredTopics) {
      // If the topic is already selected, remove it from the selected topics
      // Find the topic in the selected topics by ID
      const foundTopic = [...this._selectedTopics].find(p => p.id === topic.id);
      if (foundTopic) {
        this.removeTopic(foundTopic.id);
      } else {
        // Otherwise, add it to the selected topics
        const topic = this.getTopicDataFromCard(topicCard);
        this._selectedTopics.add(topic);
      }
      this.updateStateAndRender();
    }
  }

  removeTopic(topicId) {
    this._selectedTopics.delete([...this._selectedTopics].find(p => p.id === topicId));
    this.updateStateAndRender();
  }

  updateStateAndRender() {
    this._isUpdating = true;
    this.render();
    this.updateSelectedTopicIds();
    this._isUpdating = false;
  }

  render() {
    this.renderAvailableTopics();
    this.renderSelectedTopics();
    this.renderSelectedTopicsSummary();
    this.updateHiddenInput();
  }

  renderAvailableTopics() {
    const availableContainer = this.querySelector('.available-topics');
    // availableContainer.innerHTML = '';

    // If the available container is empty, move all topic cards into it
    if (!availableContainer.children.length) {
      this.querySelectorAll('topic-card').forEach(card => {
        const isSelected = [...this._selectedTopics].some(p => p.id === card.getAttribute('topic-id'));
        card.classList.toggle('selected', isSelected);
        availableContainer.appendChild(card);
      });
    }

    if (this.selectedTopicsSlotCount() < this._requiredTopics) {
      // For each topic card, add or remove the 'hidden' class based on whether it's in the selected topics
      this.querySelectorAll('topic-card').forEach(card => {
        const isSelected = [...this._selectedTopics].some(p => p.id === card.getAttribute('topic-id'));
        card.classList.toggle('selected', isSelected);
      })

      availableContainer.style.display = 'block';
    } else {
      availableContainer.style.display = 'none';
    }
  }

  renderSelectedTopicsSummary() {
    const selectedSummary = this.querySelector('.selected-topics-summary')
    selectedSummary.innerHTML = `
      <h4>Selected ${this._topicsLabel} (${this.selectedTopicsSlotCount()} of ${this._requiredTopics})</h4>
      <label>Choose up to ${this._requiredTopics} topics</label>
    `
  }

  renderSelectedTopics () {
    const selectedContainer = this.querySelector('.selected-topics')
    selectedContainer.innerHTML = '';

    this._selectedTopics.forEach(topic => {
      const selectedCard = document.createElement('div');
      selectedCard.className = 'topic-card selected';
      selectedCard.innerHTML = `
        <img src="${topic.thumbnail}" alt="Thumbnail for ${topic.title}" class="topic-thumbnail">
        <div class="topic-details">
            <h5 class="topic-title">${topic.title}</h5>
            <p class="topic-duration">${topic.duration}</p>
            <div class="topic-description">
                <p>${topic.description}</p>
            </div>
            <a href="#" class="remove-topic" data-id="${topic.id}">
                <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                </svg>
            </a>
        </div>
      `;
      selectedContainer.appendChild(selectedCard);

      if (this.selectedTopicsSlotCount() < this._requiredTopics) {
        selectedCard.classList.add('hidden');
      } else {
        selectedCard.classList.remove('hidden');
      }
    });
  }

  updateHiddenInput() {
    const hiddenInput = this.querySelector('input[name="' + TopicSelector.InputName + '"]');
    hiddenInput.value = [...this._selectedTopics].map(p => p.id).join(',');
  }

  updateSelectedTopicIds() {
    const newValue = [...this._selectedTopics].map(p => p.id).join(',');
    if (this.getAttribute('selected-topic-ids') !== newValue) {
      this.setAttribute('selected-topic-ids', newValue);
    }
  }
}
