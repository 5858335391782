export class FileUpload extends HTMLElement {
  constructor() {
    super();
    this.id = this.getAttribute('id') || 'file-upload';
    this.name = this.getAttribute('name') || 'file';
    this.defaultImage = this.getAttribute('default-image') || '/static/img/placeholder-photo.svg';
    this.maxFiles = parseInt(this.getAttribute('max-files') || '1', 10);
    this.acceptedExtensions = this.getAttribute('accept') || 'image/*';
    this.maxUploadSize = this.getAttribute('max-upload-size') || 'Not specified';
    this.uploadUrl = this.getAttribute('upload-url') || '';
    this.render();
    this.setupEventListeners();
  }

  _renderInput() {
    if (this.uploadUrl) {
      return `
        <input id="${this.id}-input" 
               type="file" 
               name="${this.name}" 
               hx-encoding="multipart/form-data" 
               hx-post="${this.uploadUrl}" 
               hx-trigger="change"
               ${this.maxFiles > 1 ? 'multiple' : ''} 
               accept="${this.acceptedExtensions}">
      `;
    } else {
      return `
        <input id="${this.id}-input" 
               type="file" 
               name="${this.name}" 
               ${this.maxFiles > 1 ? 'multiple' : ''} 
               accept="${this.acceptedExtensions}">
      `;
    }
  }

  render() {
    this.innerHTML = `
      <div class="file-upload-container">
        <div class="image-preview">
          <img src="${this.defaultImage}" alt="Preview image">
        </div>
        <div class="file-input-wrapper">
          <label for="${this.id}-input" class="custom-file-upload">
            Choose ${this.maxFiles > 1 ? 'files' : 'a file'}
          </label>
          ${this._renderInput()}
          <span class="file-name">No file chosen</span>
          <p class="file-constraints">
            Max files: ${this.maxFiles}, 
            Accepted types: ${this.acceptedExtensions}, 
            Max size: ${this.maxUploadSize}
          </p>
        </div>
      </div>
      <div class="progress-bar-container">
        <div class="progress-bar"></div>
      </div>
    `;
  }

  setupEventListeners() {
    this.fileInput = this.querySelector('input[type="file"]');
    this.fileNameSpan = this.querySelector('.file-name');
    this.progressBar = this.querySelector('.progress-bar');
    this.progressBarContainer = this.querySelector('.progress-bar-container');
    this.imagePreview = this.querySelector('.image-preview img');

    this.fileInput.addEventListener('change', this.handleFileChange.bind(this));

    // HTMX event listeners
    document.body.addEventListener('htmx:xhr:loadstart', this.onLoadStart.bind(this));
    document.body.addEventListener('htmx:xhr:progress', this.onProgress.bind(this));
    document.body.addEventListener('htmx:xhr:loadend', this.onLoadEnd.bind(this));
    document.body.addEventListener('htmx:xhr:abort', this.onAbort.bind(this));
  }

  handleFileChange(event) {
    const files = Array.from(event.target.files).slice(0, this.maxFiles);
    if (files.length === 0) {
      this.fileNameSpan.textContent = 'No file chosen';
      this.imagePreview.src = this.defaultImage;
      return;
    }

    if (this.maxFiles > 1) {
      this.fileNameSpan.textContent = `${files.length} file(s) selected`;
      this.previewMultipleImages(files);
    } else {
      this.fileNameSpan.textContent = files[0].name;
      this.previewImage(files[0]);
    }
  }

  previewImage(file) {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.imagePreview.src = this.defaultImage;
    }
  }

  previewMultipleImages(files) {
    // For simplicity, we'll just preview the first image
    if (files[0] && files[0].type.startsWith('image/')) {
      this.previewImage(files[0]);
    } else {
      this.imagePreview.src = this.defaultImage;
    }
  }

  onLoadStart(event) {
    if (event.detail.elt !== this.fileInput) return;
    this.progressBarContainer.style.display = 'block';
    this.progressBar.style.width = '0%';
  }

  onProgress(event) {
    if (event.detail.elt !== this.fileInput) return;
    const percentComplete = (event.detail.loaded / event.detail.total) * 100;
    this.progressBar.style.width = percentComplete + '%';
  }

  onLoadEnd(event) {
    if (event.detail.elt !== this.fileInput) return;
    this.progressBarContainer.style.display = 'none';
  }

  onAbort(event) {
    if (event.detail.elt !== this.fileInput) return;
    this.progressBarContainer.style.display = 'none';
  }
}
